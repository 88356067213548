import { apiURL } from '@/config';

export function isExternalLink(link) {
  const regex = new RegExp(/^http(|s):\/\//);

  return regex.test(link);
}

export function replaceApiUrl(link) {
  return link.replace(`https://${apiURL}`, '');
}