import React from "react";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import Error from 'next/error';
import * as Sentry from '@sentry/node';


function MyError({ statusCode = 404 }) {
  return <ErrorPage statusCode={statusCode} />;
}

MyError.getInitialProps = async ({ res, err, asPath }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const errorInitialProps = await Error.getInitialProps({ res, err });
  errorInitialProps.hasGetInitialPropsRun = true;

  if (res) {
    // Running on the server, the response object is available.
    //
    // Next.js will pass an err on the server if a page's `getInitialProps`
    // threw or returned a Promise that rejected

    if (statusCode === 404) {
      // Opinionated: do not record an exception in Sentry for 404
      return { statusCode: 404 }
    }

    if (err) {
      Sentry.captureException(err)

      return errorInitialProps
    }
  } else {
    // Running on the client (browser).
    //
    // Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html
    if (err) {
      Sentry.captureException(err)

      return errorInitialProps
    }
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.tsx getInitialProps missing data at path: ${asPath}`)
  )


  return errorInitialProps;
}

export default MyError;
