import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Link from 'next/link';
import SVG from "react-inlinesvg";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import { isExternalLink, replaceApiUrl } from "@/lib/linkHelper";

export const StyledButton = styled.a`
  align-items: center;
  justify-content: center;
  border: none;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding: ${pxToRem(13)} ${pxToRem(25)};
  margin-top: 20px;
  text-transform: uppercase;
  align-self: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  }

  &.u-mobile-full-width {
    width:100%;
  }

  ${mq.lg(css`
  &.u-mobile-full-width {
      width:auto;
      
    }
  `)}
  
  &.u-nowrap {
    white-space: nowrap;
  }

  ${({ light }) =>
    light &&
    css`
      font-weight: 400;
      text-transform: none;
      justify-content: flex-start;
    `}

  ${mq.lg(css`
    display: inline-flex;
  `)}

  ${({ icon, iconPosition }) =>
    icon &&
    css`
      padding-right: ${iconPosition && iconPosition === "before"
        ? 0
        : pxToRem(50)};

      &:hover {
        svg {
          transition: right 0.3s ease-in-out;
          right: 30px;
        }
      }
    `}

  ${({ marginTop }) =>
    marginTop &&
    `
      margin-top: ${marginTop};
  `}

  ${({ type }) =>
    type === "primary" &&
    `
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  `}

  ${({ type }) =>
    type === "secondary" &&
    `
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  `}

  ${({ type }) =>
    type === "black" &&
    `
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  `}

  ${({ type, iconColor, iconPosition }) =>
    (type === "blank" || type === "transparent") &&
    css`
      background-color: transparent;
      color: ${theme.colors.black};
      margin: 0;
      padding-top: 0;
      padding-left: ${iconPosition && iconPosition === "before"
        ? pxToRem(30)
        : 0};
      padding-bottom: 0;

      svg {
        fill: ${iconColor ? iconColor : theme.colors.black};
      }

      &:hover {
        box-shadow: none;
      }
    `}

  svg {
    margin-left: ${pxToRem(10)};
    width: .9em;
    flex-shrink: 0;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);

    ${({ iconPosition }) =>
    iconPosition &&
    iconPosition === "before" &&
    css`
        right: 0;
        left: 0;
        transform: translateY(-50%) rotate(-180deg);
        margin-left: 0;
      `}
  }
  ${({ hoverPosition }) =>
    hoverPosition &&
    hoverPosition === "down" &&
    css`
  &:hover {
    svg {
      transition: margin-top 0.3s ease-in-out;
      margin-top:5px;
      right: 35px;
    }
  }
    `}

  svg,
  path {
    transition: fill 200ms ease;
    fill: ${theme.colors.white};

    ${({ iconColor }) =>
    iconColor &&
    css`
        fill: ${iconColor};
      `}
  }

`;

const Button = (props) => {
  const {
    as,
    children,
    type,
    icon = 'arrow-right',
    iconColor,
    iconPosition,
    style,
    onClick,
    light,
    className,
    hoverPosition,
    absoluteUrl = false,
    download = false,
  } = props;

  const href = absoluteUrl ? props.href : replaceApiUrl(props.href);

  if (as) {
    return (
      <StyledButton
        as={as}
        onClick={onClick}
        type={type}
        iconColor={iconColor}
        style={style}
        css={css}
        icon={icon}
        iconPosition={iconPosition}
        light={light}
        className={className}
      >
        <span>{children}</span>
        {!icon ? <SVG src="/static/svg/arrow-right.svg" /> : <SVG src={`/static/svg/${icon}.svg`} />}
      </StyledButton>
    );
  }

  if (isExternalLink(href)) {
    return (
      <StyledButton
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        type={type}
        iconColor={iconColor}
        style={style}
        css={css}
        icon={icon}
        iconPosition={iconPosition}
        light={light}
        download={download}
        hoverPosition={hoverPosition}
        className={className}
      >
        {children}
        <SVG src={`/static/svg/${icon}.svg`} />
      </StyledButton>
    );
  }

  return (
    <Link key={href} href={href} passHref  >
      <StyledButton
        type={type}
        iconColor={iconColor}
        style={style}
        css={css}
        icon={icon}
        iconPosition={iconPosition}
        light={light}
        download={download}
        hoverPosition={hoverPosition}
      >
        {children}
        <SVG src={`/static/svg/${icon}.svg`} />
      </StyledButton>
    </Link>
  );
};

Button.defaultProps = {
  icon: 'arrow-right',
  href: "#"
};

export default Button;
