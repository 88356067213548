import React from "react";
import { injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import Container from "../Container/Container";
import { pxToRem } from "@/lib/functions";
import { Subheadline, P } from "../GlobalStyles/GlobalStyles";

import ButtonGroup from "../ButtonGroup/ButtonGroup";
import Button from "../Button/Button";

const ErrorPageWrapper = styled.section`
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 60, 90, 0),
      #00273b,
      #063a53
    ),
    url(/static/img/error-background.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: ${pxToRem(50)} ${pxToRem(20)};
  text-align: center;

  ${Container} {
    ${mq.md(css`
      max-width: ${pxToRem(650)};
    `)}
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: ${pxToRem(10)};
`;

const Title = styled.span`
  font-size: 4em;
  line-height: 1;
  font-weight: 800;
  color: white;
  margin: 0 ${pxToRem(10)};

  ${mq.md(css`
    font-size: 14em;
  `)}
`;

const StyledSVG = styled(SVG)`
  width: 3em;
  fill: white;

  ${mq.md(css`
    width: 10em;
  `)}
`;

const ErrorPage = ({ statusCode, intl }) => {
  return (
    <ErrorPageWrapper>
      <Container>
        <TitleWrapper>
          {statusCode && statusCode
            .toString()
            .split("")
            .map((number, index) => {
              if (statusCode === 404 && index == 1) {
                return <StyledSVG key={`status-${index}`} src={"/static/svg/ring.svg"} />;
              } else {
                return <Title key={`status-${index}`}>{number}</Title>;
              }
            })}
          {/* <Title>{statusCode}</Title> */}
        </TitleWrapper>
        <Subheadline
          css={css`
            color: white;
          `}
          bold
        >
          {statusCode === 500
            ? intl.messages["error.server.title"]
            : intl.messages["error.page.title"]}
        </Subheadline>

        {statusCode !== 500 && (
          <>
            <P
              as="div"
              dangerouslySetInnerHTML={{
                __html: intl.messages["error.page.text"]
              }}
              css={css`
                color: white;
                font-weight: 300;
              `}
            ></P>
            <ButtonGroup style={{ justifyContent: "center" }}>
              <Button
                href={`${intl.locale === "de" ? "/" : "/en/"}`}
                type="primary"
              >
                {intl.messages["error.page.startPage"]}
              </Button>
              <Button
                href={`${intl.locale === "de" ? "/karriere/jobs" : "/en/career/jobs/"
                  }`}
                type="primary"
              >
                {intl.messages["error.page.jobPage"]}
              </Button>
              <Button
                href={`${intl.locale === "de" ? "/kontakt/" : "/en/contact/"}`}
                type="primary"
              >
                {intl.messages["error.page.contactPage"]}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Container>
    </ErrorPageWrapper>
  );
};

export default injectIntl(ErrorPage);
