import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledButton as Button } from "../Button/Button";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Button} {
    margin: ${pxToRem(10)} 0;
  }

  ${mq.lg(css`
    ${Button} {
      margin: ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(10)} 0;
    }
  `)}
`;

export default ButtonGroup;
